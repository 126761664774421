.demo-editor{
    width: 100%;
    height: auto;
    overflow-y: scroll;
}

.ql-2-toolbar{
    border:0px;
    box-sizing: border-box;
    font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    padding: 8px;
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.ql-editor.ql-blank::before {
    color: black;
}