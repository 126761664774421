.create {
    position: relative;
    overflow: hidden;
  }

 .create:only-child{
    z-index: 10;
 } 

.create::before{
    content: "";
    width: 0;
    height: 100%;
    position: absolute;
    top: 0;
    background-color: #1ab43417;
    left: 0;
    z-index: 1;
    transition: width 0.5s ease;
    cursor: pointer;


}

.create:hover::before{
    width: 100%;
}

/***Create Brand VOice***/


 .input-create:focus-within .label-create  {
    color: #196325ef; /* Change this to the color you want on focus */
  }


/***Modal Line***/


.hrLine::before{
    content: "";
    width: 100%;
    height: 1px;
    position: absolute;
    top: 0px;
    left: 0;
    background-color: rgba(255, 255, 255, 0.363);
}