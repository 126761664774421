.progress-bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 4px;
    background-color: green;
    transition: all 0.6s ease-in-out;
  }
  
  .visible .progress-bar {
    width: 100%;
  }


.success-modal {
    width: auto;
    max-width: 410px;
    min-height: 150px;
    max-height: 170px;
    position: absolute;
    top: 5%;
    transform: translateX(-2%);
    /* left: 35%; */
    /* transform: translate(-50%,-20px); */
    background-color: white; /* Green background color for success */
    color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 999;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  
  .visible-model {
    opacity: 1;
    /* right: -50%; */
  }
  
  .hidden-model {
    opacity: 0;
    /* right: 0%; */

  }
  
  .success-modal p {
    margin: 0;
    font-size: 16px;
  }