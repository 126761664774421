 .tab::before{
    content: "";
    width: 0;
    height: 100%;
    position: absolute;
    background-color: transparent;
    transition: all 0.7s ease;
    left: 0;
}

.tab:hover::before{
    width: 100%;
    transition: all 0.5s ease;
    background-color: rgba(239, 239, 242, 0.071);
} 



 .deactive-pho-tab:after{
    content: "";
    width: 0%;
    height: 5px;
    background-color: gray;
    position: absolute;
    left: 0;
    transition: all 0.5s ease;
    bottom:0 ;
}

.active-pho-tab:after{
    content: "";
    width: 100%;
    height: 5px;
    background-color: greenyellow;
    position: absolute;
    left: 0;
    transition: all 0.5s ease;
    bottom:0
} 