/* custom-scrollbar.css */
/* Webkit Scrollbar */
.scrollbar::-webkit-scrollbar {
    width: 6px; /* Set the width to make it thin */
}

.scrollbar::-webkit-scrollbar-thumb {
    background: #888; /* Color of the scrollbar thumb */
}

