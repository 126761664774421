 .Popup {
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 110%;
    left: 4%;
    margin-left: -60px;
  }
  
 .Popup::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: rgb(255, 255, 255) transparent transparent transparent;
    box-shadow: 4px 6px ;
    
  }


  .radio-container::-webkit-scrollbar{
    width: 8px;
}

.radio-container::-webkit-scrollbar-track{
    width: 15px;
    background-color: none;
}

.radio-container::-webkit-scrollbar-thumb{
    width: 6px;
    background-color: gray;
    border-radius: 20px;
}